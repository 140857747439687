


























import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator';
import _ from 'lodash';

import FirmStructure from '@models/firm/FirmStructure';

interface TreeElement {
  id: number;
  label: string;
  type: string;
  parent: number | null;
  children?: TreeElement[];
}

@Component
export default class FirmStructureTree extends Vue {
  @Prop({ type: Array })
  readonly firmStructure!: FirmStructure[];

  @Prop({ type: Boolean })
  readonly disabled!: false;

  @Prop({ type: Array, default: () => [] })
  value!: Array<number>;

  defaultProps: object = {
    children: 'children',
    label: 'label',
  };

  $refs!: {
    tree: any,
  };

  elements: Record<number, TreeElement> = {};

  get structures(): Array<TreeElement> {
    const data: TreeElement[] = [];
    this.elements = {};

    this.firmStructure.forEach((r) => {
      let root = {
        id: r.id as number,
        label: r.label,
        parent: null,
        type: 'Unit',
        children: [],
      };
      this.elements[root.id] = root;
      root = this.buildTree(root, r);
      data.push(root);
    });

    return data;
  }

  @Watch('value', { immediate: true })
  onValueChange(value: number[]) {
    this.setTreeValues(value);
  }

  buildTree(data: TreeElement, currentNode: any): any {
    data.children = [];
    currentNode.jobs.forEach((job: any) => {
      const jobElement = {
        id: job.id,
        label: job.label,
        type: 'Job',
        parent: currentNode.id,
      };

      if (data.children) {
        data.children.push(jobElement);
        this.elements[jobElement.id] = jobElement;
      }
    });

    currentNode.children.forEach((child: any) => {
      let newChild = {
        id: child.id,
        label: child.label,
        parent: currentNode.id,
        type: 'Unit',
        children: [],
      };
      newChild = this.buildTree(newChild, child);
      if (data.children) {
        data.children.push(newChild);
        this.elements[newChild.id] = newChild;
      }
    });
    return data;
  }

  handleCheck(e: any): void {
    const TreeElement: any = this.$refs.tree;

    let checked = this.selectOnlyParent(TreeElement.getCheckedKeys());
    const diff = _.difference(this.value, checked);
    diff.forEach((diffId) => {
      checked = checked.filter((id) => {
        const element = this.elements[id];
        return element.parent !== diffId;
      });
    });

    this.$emit('input', checked);
  }

  selectOnlyParent(checked: number[]): number[] {
    const filtered: number[] = [];
    checked.forEach((k) => {
      const element = this.elements[k];
      if (element.parent === null || !checked.includes(element.parent)) {
        filtered.push(element.id);
      }
    });

    return filtered;
  }

  getElementWithChildrenId(values: number[]) {
    function getWithChildrenIds(value: number, elements: Record<number, TreeElement>) {
      const ids: number[] = [];
      ids.push(value);
      const children = Object.values(elements).filter(child => child.parent === value);
      children.forEach((child) => {
        ids.push(...getWithChildrenIds(child.id, elements));
      });

      return ids;
    }

    const elements: number[] = [];
    values.forEach((id) => {
      elements.push(...getWithChildrenIds(id, this.elements));
    });

    return elements;
  }

  setTreeValues(values: number[]) {
    this.$nextTick().then(() => {
      this.$refs.tree.setCheckedKeys(this.getElementWithChildrenId(values));
    });
  }
}
