












































































































































































































































































































import _ from 'lodash';
import moment from 'moment';
import Routing from 'fos-routing';
import { namespace } from 'vuex-class';
import { Vue, Component } from 'vue-property-decorator';

import { VForm } from '@/types/v-form';
import { ActionInterface } from '@/types/breadcrumb';
import * as Rules from '@/utils/CustomFormRules';

import Layout from '@/layouts/LayoutDefault.vue';
import FirmStructureTree from '@/components/utils/FirmStructureTree.vue';
import Modal from '@/components/Modal.vue';

import FirmStructure from '@models/firm/FirmStructure';
import ProductSheet from '@modules/chemical-risk/entities/ProductSheet';
import Product from '@modules/chemical-risk/entities/Product';
import Substance from '@modules/chemical-risk/entities/Substance';
import HazardStatement from '@modules/chemical-risk/entities/HazardStatement';
import Pictogram from '@modules/chemical-risk/entities/Pictogram';

const cheModule = namespace('cheRisk');
const firmModule = namespace('firm');

@Component({
  components: {
    Layout,
    FirmStructureTree,
    Modal,
  },
})
export default class InventoryEditView extends Vue {
  @firmModule.Getter('getOrganization')
  firmStructure!: FirmStructure[];

  @cheModule.State('currentProduct')
  currentProduct!: Product;

  @cheModule.Action('postProduct')
  postProduct!: (form: any) => Promise<any>;

  @cheModule.Action('patchProduct')
  patchProduct!: any;

  @firmModule.Action('getActivesUnits')
  getActivesUnits!: () => any;

  @cheModule.Action('getProductSheets')
  getProductSheets!: (payload: any) => any;

  @cheModule.Getter('getHazardStatement')
  getHazardStatement!: (id: number) => HazardStatement;

  @cheModule.Getter('getPictogram')
  getPictogram!: (id: number) => Pictogram;

  $refs!: {
    form: VForm
    productSelect: any
  };

  fileList: any = [];
  form: any = {
    product: new Product(),
    productSheet: new ProductSheet(),
    structures: [],
  };
  optionsStatus: Array<Object> = [
    {
      value: 1,
      label: 'Production',
    },
    {
      value: 2,
      label: 'Essai',
    },
    {
      value: 3,
      label: 'Sortie',
    },
  ];
  fdsList: Array<Object> = [];
  loading: Boolean = false;
  isModel: Boolean = false;
  previewVisible: Boolean = false;
  previewFDS: ProductSheet | null = null;

  get rules() {
    return {
      product: {
        outputDate: [
          { required: this.form.product.status === 3, message: 'Veuillez saisir une valeur', trigger: 'change' },
          {
            message: 'Veuillez saisir une valeur supérieur à la date d\'entrée',
            trigger: 'blur',
            validator: Rules.momentGreaterThan,
            date: moment(this.form.product.inputDate),
          },
        ],
      },
      productSheet: {
        label: [
          { required: true, message: 'Veuillez saisir une valeur', trigger: 'change' },
        ],
        // #20333 Le fichier ne doit plus être obligatoire
        // file: [
        //   { required: !this.isModel, message: 'Veuillez saisir une valeur', trigger: 'change' },
        // ],
        year: [
          { required: true, message: 'Veuillez saisir une valeur', trigger: 'change' },
          {
            message: 'Si possible, enregistrer une FDS datant de moins de 5 ans',
            trigger: 'blur',
            validator: Rules.momentGreaterThan,
            date: moment().subtract(5, 'years'),
          },
        ],
        vendor: [
          { required: true, message: 'Veuillez saisir une valeur', trigger: 'change' },
        ],
      },
      structures: [
        {
          message: 'Veuillez renseigner une affectation',
          trigger: 'blur',
          validator: Rules.validateArray,
        },
      ],
    };
  }

  get actions(): ActionInterface[] {
    return this.isGranted('chemi-risks-inventory', 2) ? [
      {
        label: 'Enregistrer et revenir',
        handler: () => this.validate(),
      },
      {
        label: 'Enregistrer et ajouter',
        handler: () => this.validate(true),
      },
      {
        label: 'Annuler',
        handler: () => this.handleRedirect('chemi-risks-inventory'),
      },
    ] : [];
  }

  async preload() {
    await this.getActivesUnits();
  }

  validateDropdown(field: any) {
    (this.$refs.form as VForm).validateField(field);
  }

  validate(redirectAdd: boolean = false) {
    let validForm = false;
    let errorFields = {};
    this.$refs.form.validate((valid, errors) => {
      validForm = valid;
      errorFields = errors;
    });

    if (!validForm) {
      if (Object.keys(errorFields).length === 1 && (errorFields as any)['productSheet.year'] !== undefined && (errorFields as any)['productSheet.year'][0].message === 'Si possible, enregistrer une FDS datant de moins de 5 ans') {
        return this.save().then(() => {
          if (redirectAdd) {
            window.location.reload();
          } else {
            this.handleRedirect('chemi-risks-inventory');
          }
        });
      }
      this.$message({
        type: 'warning',
        message: 'Certains champs obligatoire ne sont pas renseignés',
      });
      return Promise.reject(errorFields);
    }

    return this.save().then(() => {
      if (redirectAdd) {
        window.location.reload();
      } else {
        this.handleRedirect('chemi-risks-inventory');
      }
    });
  }

  save() {
    this.form.product.productSheet = this.form.productSheet;
    this.form.product.structures = this.form.structures;
    if (this.$route.params.id) {
      return this.patchProduct(this.form.product).then((response: any) => {
        this.$message({
          type: 'success',
          message: 'Données mises à jour',
        });
      }).catch((error: any) => {
        this.$message({
          type: 'error',
          message: 'Erreur lors de la mise à jour',
        });
      });
    }
    return this.postProduct(this.form.product).then(() => {
      this.$message({
        type: 'success',
        message: 'Données enregistrées',
      });
    }).catch((error: any) => {
      this.$message({
        type: 'error',
        message: 'Erreur lors de la mise à jour',
      });
      throw error;
    });
  }

  getLabelFDSList(query: string) {
    if (query !== '') {
      this.loading = true;
      this.form.productSheet.label = query;
      this.isModel = false;
      this.form.productSheet.isPublic = false;
      setTimeout(async () => {
        this.loading = false;
        this.fdsList = await this.getProductSheets({
          label: encodeURIComponent(query),
          vendor: this.form.productSheet.vendor,
          year: this.form.productSheet.year,
        });
      }, 200);
    } else {
      this.fdsList = [];
    }
  }

  getVendorFDSList(query: string) {
    if (query !== '') {
      this.loading = true;
      this.form.productSheet.vendor = query;
      this.isModel = false;
      this.form.productSheet.isPublic = false;
      setTimeout(async () => {
        this.loading = false;
        this.fdsList = await this.getProductSheets({
          label: encodeURIComponent(this.form.productSheet.label),
          vendor: query,
          year: this.form.productSheet.year,
        });
      }, 200);
    } else {
      this.fdsList = [];
    }
  }

  getYearFDSList(query: string) {
    if (query !== '') {
      this.loading = true;
      this.form.productSheet.year = query;
      this.isModel = false;
      this.form.productSheet.isPublic = false;
      setTimeout(async () => {
        this.loading = false;
        this.fdsList = await this.getProductSheets({
          label: encodeURIComponent(this.form.productSheet.label),
          vendor: this.form.productSheet.vendor,
          year: query,
        });
      }, 200);
    } else {
      this.fdsList = [];
    }
  }

  useModel(fds: ProductSheet) {
    this.form.productSheet = _.cloneDeep(fds);
    this.form.productSheet.substances.forEach((substance: Substance) => {
      if (substance.CASNumber) {
        substance.CASNumber = this.useCasNumber(substance.CASNumber);
      }
    });
    this.form.productSheet.year = this.form.productSheet.year ? moment(this.form.productSheet.year).format('L') : '';
    this.form.productSheet.isPublic = true;
    this.isModel = true;
    const refs: any = this.$refs.productSelect;
    refs.blur();
  }

  useCasNumber(cas: string) {
    return cas.split(';')[0];
  }

  resetModelBool() {
    if (this.isModel) {
      this.isModel = false;
      this.form.productSheet.year = '';
      this.form.productSheet.vendor = '';
      this.form.productSheet.label = '';
    }
  }

  removeModel(fds: ProductSheet) {
    this.isModel = false;
    this.form.productSheet.label = '';
    this.form.productSheet.uuid = '';
    this.form.productSheet.isPublic = false;
    const refs: any = this.$refs.productSelect;
    refs.blur();
  }

  preview(fds: ProductSheet) {
    this.previewFDS = fds;
    this.previewVisible = true;
  }

  uploadRoute() {
    return Routing.generate('api_post_pr_product_file');
  }

  handleUploadSuccess(response: any) {
    this.form.productSheet.file = response;
    this.fileList = [{ name: response, url: `/upload/product_sheets/${this.form.productSheet.file}` }];
  }

  handlePreview(file: any) {
    if (this.$route.params.id) {
      window.open(file.url);
    }
  }

  handleRemove(file: any) {
    this.form.productSheet.file = '';
    this.fileList = [];
  }

  momentify(date: string) {
    return moment(date).format('L');
  }

  public async created() {
    await this.preload();
    if (this.$route.params.id) {
      this.form.product = _.cloneDeep(this.currentProduct);
      this.form.productSheet = this.form.product.productSheet;
      this.form.structures = this.form.product.structures.map((s: any) => s.id as number);
      if (this.form.productSheet.file !== '' && this.form.productSheet.file != null) {
        this.fileList = [{
          name: this.form.productSheet.file,
          url: this.form.productSheet.isPublic ? this.form.productSheet.file : `${process.env.EMPR1TE_URL}/upload/product_sheets/${this.form.productSheet.file}`,
        }];
      }
    }
    if (this.$route.params.status) {
      this.form.product.status = 3;
    }
  }
}
