import moment from 'moment';

export const dateLessThan = (rule: any, value: Date, callback: (p?: any) => void): void => {
  const errors = [];
  // eslint-disable-next-line no-prototype-builtins
  if (rule.hasOwnProperty('objects')) {
    const active = rule.objects[rule.field.split('.')[1]].isActive;
    if (active && value && rule.date && value.getTime() > rule.date.getTime()) {
      errors.push(new Error(rule.message));
    }
  } else if (value && rule.date && value.getTime() > rule.date.getTime()) {
    errors.push(new Error(rule.message));
  }

  callback(errors);
};

export const dateGreaterThan = (rule: any, value: Date, callback: (p?: any) => void): void => {
  const errors = [];
  // eslint-disable-next-line no-prototype-builtins
  if (rule.hasOwnProperty('objects')) {
    const active = rule.objects[rule.field.split('.')[1]].isActive;
    if (active && value && rule.date && value.getTime() < rule.date.getTime()) {
      errors.push(new Error(rule.message));
    }
  } else if (value && rule.date && value.getTime() < rule.date.getTime()) {
    errors.push(new Error(rule.message));
  }

  callback(errors);
};

export const momentGreaterThan = (rule: any, value: Date, callback: (p?: any) => void): void => {
  const errors = [];
  if (value && rule.date && moment(value, 'DD/MM/YYYY').isBefore(rule.date)) {
    errors.push(new Error(rule.message));
  }

  callback(errors);
};

export const validateNumSecu = (rule: any, value: any, callback: (p?: any) => void): void => {
  if (!value || !value.length) {
    callback();
    return;
  }

  let error = null;
  const num = value.replace(/[ _]/g, '');

  if (num.length !== 15) {
    error = new Error(rule.message);
  }

  const code = parseInt(num.substr(0, 13).replace('2A', '19').replace('2B', '18'), 10);
  const checksum = parseInt(num.substr(13, 14), 10);
  if (97 - (code % 97) !== checksum) {
    error = new Error(rule.messageChecksum);
  }

  if (rule.personSex != null) {
    // eslint-disable-next-line eqeqeq
    if ((rule.personSex == 2) || (rule.personSex == 1)) {
      if (!value.includes(rule.personSex, 0)) {
        error = new Error(rule.messageCheckSecu);
      }
    }
  }
  if (error) {
    callback(error);
  } else {
    callback();
  }
};

export const checkBoolean = (rule: any, value: any, callback: (p?: any) => void): void => {
  const errors = [];
  if (!rule.boolValue) {
    errors.push(new Error(rule.message));
  }
  callback(errors);
};

export const validateCASNumber = (rule: any, value: any, callback: (p?: any) => void): void => {
  const errors = [];
  const regex = /[-()]/gi;
  let valueNormalized = value.replace(regex, '');
  const i = valueNormalized.indexOf(';');
  if (i !== -1) {
    valueNormalized = valueNormalized.substring(0, i !== -1 ? i : valueNormalized.length);
  }
  const casNumbers = Array.from(String(valueNormalized), Number);
  casNumbers.reverse();
  const totalToFind = casNumbers.shift();
  let total: number = 0;
  casNumbers.forEach((n: number, index: number) => {
    total += n * (index + 1);
  });
  if (totalToFind !== (total % 10)) {
    errors.push(new Error(rule.message));
  }
  callback(errors);
};

export const validateArray = (rule: any, value: Array<number>, callback: (p?: any) => void): void => {
  if (!value || value.length === 0) {
    callback(new Error(rule.message));
  }

  callback();
};
